<template>
  <v-app>
     <router-view/>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
            version: process.env.VUE_APP_VERSION
    }
  },
}
</script>
